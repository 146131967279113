import React from "react"

import { Container, Col } from 'reactstrap'
import Link from '../components/link'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider-operator'
import Box from '../components/box'
import { FaHome } from 'react-icons/fa';
import styled from 'styled-components'
import worldIcon from '../assets/images/world.png'

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

let Service = ({title, Icon = FaHome}) => (
  <Col>
    <Link to="/">
      <Box>
        <Icon size={30}/>
        <h4 className="mt-3">{title}</h4>
      </Box>
    </Link>
  </Col>
)

export default () => (
  <Layout>
    <SEO title="Owner Driver" />
    <Slider title="OWNER DRIVER"/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					<h4 className="surpassing"><b>SURPASSING</b> ALL BOUNDARIES FROM <b>US</b> TO <b>YOU</b></h4> 
			  </div>
			</div>
		</Container>
	</div>
	
	<div className="point-crossed-background2" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="serviceQuality">{'OWNER DRIVER'}</h4>  
				<p className="grayText3"> We believe in <b>continuos inprovement</b></p>   
			  </div> 
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"> 
				<div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 whiteBack">  
					<p className="grayText2">{'Join us to the most successful transportation companies in Northwest Mexico without losing your autonomy as the owner of your truck.\n\n '+
											  'At Gutierrez Trucking we offer you the support of our entire network so that you, as the owner driver, feel supported in each transfer you make.\n\n'+
											  'We offer you the facility to designate your hours on the road and accept only the loads that you want.' 
											}</p> 
					<p className="grayText2"><b> Are you ready to be an Owner Driver at Gutierrez Trucking ? </b> </p> 
					<div className="col-12 left colLeft">											
						<a className="locationVisit left">{'Requirements'}</a>
					</div>
					<div className="col-12 left colLeft">
						<a className="locationVisit left">{'Compensation'}</a> 
					</div>
					<div className="col-12 left colLeft">	
						<a className="locationVisit left">{'Regulatory Requirements'}</a>
					</div>
					<div className="col-12 left colLeft">	 
						<a className="locationVisit left">{'Join Us Now'}</a> 
					</div> 
				</div>
			  </div> 
			</div>
		</Container>
	</div>
  </Layout>
)
